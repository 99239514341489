import { Component } from '@angular/core';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from './services/data.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {

	navOpen = false;

	constructor(
		private Api: ApiService,
		private Auth: AuthService,
		private router: Router,
		private data: DataService
	) {
		this.router.events.subscribe(event => {

			if (event instanceof NavigationEnd) {
				this.navOpen = false;
			}

		});
	}

	get isLoggedIn(): any {
		return this.Auth.isLoggedIn();
	}

	logout() {
		this.Api.logout();
	}
}

import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(
		private cookie: CookieService,
		private router: Router
	) { }

	isLoggedIn(){
		return this.cookie.get('DBSToken');
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	) {


		if (this.isLoggedIn()) {
			return true;
		} else {

			this.router.navigate(['/login'], {
				queryParams: {
					return: state.url
				}
			});
			return false;
		}
	}
}

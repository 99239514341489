import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { ErrorsService } from '../services/errors.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	pin: string;

	constructor(
		private Api: ApiService,
		private Errors: ErrorsService,
	) { }

	ngOnInit() {
	}

	login() {
		this.Api.login({
			pin: this.pin
		});
	}

	
	get errors() : any {
		return this.Errors;
	}
	

}

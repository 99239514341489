import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import {Location} from '@angular/common';

@Component({
	selector: 'app-preview',
	templateUrl: './preview.component.html',
	styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

	_pallet : any;

	constructor(
		private route: ActivatedRoute,
		private Api: ApiService,
		private router: Router,
		private Data: DataService,
		private _location: Location
	) {
		this.Data.setTitle('Preview label');
		this.route.params.subscribe(params => {
			this.Api.get('pallets/' + params.id).subscribe(pallet => {
				this._pallet = pallet;
			});
		})
	}

	ngOnInit() {
	}

	back(){
		this._location.back();
	}

	print(){
		window.print();
	}

	get pallet() :any{
		return this._pallet || {
			project : {}
		};
	}

}

import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
	selector: 'app-place',
	templateUrl: './place.component.html',
	styleUrls: ['./place.component.scss']
})
export class PlaceComponent implements OnInit {

	pallet: any = {
		bin: {
			aisle: ''
		}
	};
	bin: any = {

	};
	binLists: any = {

	};
	scanTimeout: any;
	scan = '';

	binres:any;

	constructor(
		private route: ActivatedRoute,
		private Api: ApiService,
		private Data: DataService,
		private router: Router,
	) {
		this.Data.setTitle('Place first time');
	}

	ngOnInit() {
		this.route.params.subscribe(params => {
			this.Api.get('pallets/' + params.id).subscribe((pallet: any) => {
				this.pallet = pallet;

				if (pallet.bin) {
					this.bin.aisle = pallet.bin.aisle;
					this.bin.section = pallet.bin.section;
					this.bin.sectionDetail = pallet.bin.sectionDetail;
					this.bin.level = pallet.bin.level;
				} else {
					this.bin.projectToken = pallet.projectToken;
					this.bin.unitToken = pallet.unitToken;
					this.bin.binToken = pallet.binToken;
				}

			});
			this.Api.get('bins/all').subscribe(binLists => {
				this.binLists = binLists;
			});
		});

	}

	placePallet() {
		this.Api.save('pallets/' + this.pallet.palletToken, this.bin).subscribe(pallet => {
			this.router.navigate(['/search/move', this.pallet.palletToken]);
		});
	}

	detectScan() {
		clearTimeout(this.scanTimeout);
		this.scanTimeout = setTimeout(() => {
			this.Api.get('bins/' + this.scan).subscribe(bin => {
				this.bin = bin;
			});
		}, 50);
	}

}

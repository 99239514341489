import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DataService } from '../services/data.service';
import { Observable, Observer } from 'rxjs';
import { ApiService } from '../services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmService } from '../services/confirm.service';

@Component({
	selector: 'app-search',
	templateUrl: './search.component.html',
	styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit {


	dataSource: any;
	searchIn: any = 'pallets';
	query: any = '';
	unitQuery: any = '';
	palletQuery: any = '';
	units: any = [];
	pallets: any = [];
	accessories: any = [];
	scanTimeout: any;
	scan = '';
	projectToken: String;
	emptyBin = {
		aisle: '',
		section: '',
		sectionDetail: '',
		level: ''
	};
	bin: any = {};
	binLists: any = {};

	constructor(
		private Data: DataService,
		private Api: ApiService,
		private router: Router,
		public route: ActivatedRoute,
		public location: Location,
		private Confirm: ConfirmService
	) {
		this.Data.setTitle('Scan or create new label');
		this.dataSource = Observable.create((observer: Observer<any[]>) => {
			if (this.query) {
				this.Api.get('search/' + this.searchIn + '/' + this.query).subscribe((responseItems: any) => {
					observer.next(responseItems.results || []);
				});
			} else {
				observer.next([]);
			}
		});
	}

	ngOnInit() {

	}

	detectScan() {
		clearTimeout(this.scanTimeout);
		this.scanTimeout = setTimeout(() => {
			this.Api.get('pallets/' + this.scan).subscribe(res => {

				if (this.searchIn === 'bin') {
					this.pallets = res;
				} else {
					this.handlePallet(res);
				}

			});
		}, 50);
	}

	changeSearchIn() {
		this.query = '';
		this.units = [];
		this.pallets = [];
		this.accessories = [];
		this.bin = Object.assign({}, this.emptyBin);
		this.unitQuery = '';
		this.projectToken = '';

		if (this.searchIn === 'bin') {
			this.Api.get('bins/all').subscribe(binLists => {
				this.binLists = binLists;
			});
		}
	}

	onSelect(event) {

		this.router.navigate(['/search']);

		if (this.searchIn === 'pallets') {
			this.Api.get('pallets/' + (event.item.token || event.item.palletToken)).subscribe(pallet => {
				this.handlePallet(pallet);
			})
		}

		if (this.searchIn === 'projects') {
			this.projectToken = event.item.token;
			this.pallets = [];
			this.accessories = [];
			this.Api.get('units/' + event.item.token).subscribe(units => {
				this.units = units;
			})
		}

		if (this.searchIn === 'units') {
			this.onSelectUnit(event)
		}

	}

	onSelectUnit(event) {
		this.router.navigate(['/search']);
		this.Api.get('pallets/' + (event.item.token || event.item.unitToken)).subscribe(pallets => {
			this.pallets = pallets;
		})
	}

	handlePallet(pallet) {
		if (pallet.binToken) {
			this.router.navigate(['/search/move', pallet.palletToken]);
		} else {
			this.router.navigate(['/search/place', pallet.palletToken]);
		}
	}

	printPallet(pallet) {
		this.router.navigate(['/preview', pallet.palletToken]);
	}

	deletePallet(pallet) {
		this.Confirm.ask('Confirm!', 'Are you sure you want to delete?').then((res) => {
			this.Api.delete('pallets/' + pallet.palletToken).subscribe(() => {
				const palletIdx = this.pallets.findIndex(p => p.palletToken === pallet.palletToken);
				this.pallets.splice(palletIdx, 1);
			});
		});
	}

	getAllAccessories() {
		this.Api.get('pallets/' + this.projectToken + '/noUnitPallets').subscribe(res => {
			this.accessories = res;
		})
	}

	getBinPallets() {
		if (this.bin.aisle && this.bin.section && this.bin.sectionDetail && this.bin.level) {
			const binToken = `bin_${this.bin.aisle}-${this.bin.section}${this.bin.sectionDetail}-${this.bin.level}`;
			this.Api.get('pallets/' + binToken).subscribe(res => {
				this.pallets = res;
			});
		}
	}

	getAllProjectPallets() {
		this.Api.get('pallets/' + this.projectToken).subscribe(res => {
			this.pallets = res;
		})
	}

	get allPallets(): any {
		return this.pallets.concat(this.accessories);
	}

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { SearchComponent } from '../search/search.component';
import { AuthService } from '../services/auth.service';
import { MoveComponent } from '../search/move/move.component';
import { PlaceComponent } from '../search/place/place.component';
import { RemoveComponent } from '../search/move/remove/remove.component';
import { LabelComponent } from '../label/label.component';
import { PreviewComponent } from '../label/preview/preview.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: '/search',
		pathMatch: 'full'
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'search',
		component: SearchComponent,
		canActivate: [AuthService],
		children: [
			{
				path: 'move/:id',
				component: MoveComponent,
				children: [{
					path: 'remove',
					component: RemoveComponent
				}]
			},
			{
				path: 'place/:id',
				component: PlaceComponent
			}
		]
	},
	{
		path: 'label',
		component: LabelComponent,
		canActivate: [AuthService],
		children: [
			{
				path: 'preview/:id',
				component: PreviewComponent
			}
		]
	},
	{
		path: 'preview/:id',
		component: PreviewComponent,
		canActivate: [AuthService]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }

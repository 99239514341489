import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})

export class ConfirmComponent implements OnInit {

  @Input() title: string = '';
  @Input() message: string = '';
  @Input() bsModalRef: any;
  @Input() ok: any;
  @Input() cancel: any;

  constructor() { }

  ngOnInit(){

  }

}




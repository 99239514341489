import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ErrorsService {
	errorsObj = {};
	constructor() { }

	setErrors(_errors){
		_errors.forEach(error => {
			this.errorsObj[error.field] = error.msg;
		});
	}

	get errors(): any {
		return this.errorsObj;
	}

	has(field): any{
		return this.errors[field];
	}

	clear(){
		this.errorsObj = {};
	}
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Router, RouterStateSnapshot } from '@angular/router';
import { ErrorsService } from './errors.service';



@Injectable({
	providedIn: 'root'
})
export class ApiService {

	baseUrl = environment.baseUrl;

	constructor(
		private http: HttpClient,
		private cookie: CookieService,
		private router: Router,
		private Errors : ErrorsService,
	) { }

	login(user){
		return this.http.post(this.baseUrl + 'login', user).subscribe((res:any) => {
			if(res.userToken){
				var now = new Date();
				now.setDate(now.getDate() + 30);
				this.cookie.set('DBSToken', res.userToken, now);
				this.router.navigate(['/']);
			}else if(res.error){
				this.Errors.setErrors(res.error);
			}
		});
	}

	logout(){
		this.cookie.delete('DBSToken');
		this.router.navigate(['/login']);
	}

	getList(path) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'token': this.cookie.get('DBSToken')
			})
		};
		return this.http.get(this.baseUrl + path, httpOptions);
	}

	get(path) {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'token': this.cookie.get('DBSToken')
			})
		};
		return this.http.get(this.baseUrl + path, httpOptions);
	}

	save(path, data){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'token': this.cookie.get('DBSToken')
			})
		};
		return this.http.post(this.baseUrl + path, data, httpOptions);
	}

	delete(path){
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				'token': this.cookie.get('DBSToken')
			})
		};
		return this.http.delete(this.baseUrl + path, httpOptions);
	}
}


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { ErrorsService } from 'src/app/services/errors.service';

@Component({
	selector: 'app-move',
	templateUrl: './move.component.html',
	styleUrls: ['./move.component.scss']
})
export class MoveComponent implements OnInit {

	pallet: any = {
		bin: {}
	};

	constructor(
		private Api: ApiService,
		private Data: DataService,
		private Error: ErrorsService,
		public router: Router,
		public route: ActivatedRoute,
	) {
		this.Data.setTitle('Pallet Location');
	}

	ngOnInit() {
		this.route.params.subscribe(params => {
			this.Api.get('pallets/' + params.id).subscribe(pallet => {
				this.pallet = pallet;
			});
		})
	}

	setBoxCount(direction) {
		this.pallet.itemCount = direction === '+' ? this.pallet.itemCount + 1 : Math.max(0, this.pallet.itemCount - 1);
		this.updateItemCount();
	}

	updateItemCount() {
		this.Api.save('pallets/' + this.pallet.palletToken, {
			itemCount: this.pallet.itemCount
		}).subscribe((res: any) => {
			if (res.error) {
				this.Error.setErrors(res.error);
			}else{
				this.Error.clear();
			}
		});
	}

	movePallet() {
		this.router.navigate(['/search/place/' + this.pallet.palletToken]);
	}

	removePallet() {
		this.router.navigate(['/search/move/' + this.pallet.palletToken + '/remove']);
	}

}

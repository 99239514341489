import { Component, Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ConfirmComponent } from '../components/confirm/confirm.component';

@Injectable({
	providedIn: 'root'
})
export class ConfirmService {

	bsModalRef: any;
	modalPromise:any;

	constructor(
		private modalService: BsModalService
	) { }

	ask(title?: string, message?: string) {
		
		this.modalPromise = new Promise((resolve, reject) => {
		
			this.bsModalRef = this.modalService.show(ConfirmComponent, { class: 'modal-md' });
			this.bsModalRef.content.title = title;
			this.bsModalRef.content.message = message;
			this.bsModalRef.content.cancel = () => {
				this.bsModalRef.hide();
			};
			this.bsModalRef.content.ok = () => {
				resolve('Ok');
				this.bsModalRef.hide();
			};

		});

		return this.modalPromise;
	}

}

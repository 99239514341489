import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-remove',
	templateUrl: './remove.component.html',
	styleUrls: ['./remove.component.scss']
})
export class RemoveComponent implements OnInit {

	removeObj: any = {

	}
	palletToken: any;

	constructor(
		private Data: DataService,
		private Api: ApiService,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.Data.setTitle('Remove');
	}

	ngOnInit() {
		this.route.parent.params.subscribe(params => {
			console.log(params);
			this.palletToken = params.id;
		});
	}

	removePallet() {
		this.Api.save('pallets/' + this.palletToken + '/remove', this.removeObj).subscribe(pallet => {
			this.router.navigate(['/search']);
		});
	}

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap';
import { TypeaheadModule } from 'ngx-bootstrap';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { ErrorComponent } from './components/error/error.component';
import { SearchComponent } from './search/search.component';
import { MoveComponent } from './search/move/move.component';
import { PlaceComponent } from './search/place/place.component';
import { RemoveComponent } from './search/move/remove/remove.component';
import { LabelComponent } from './label/label.component';
import { SvgComponent } from './components/svg/svg.component';
import { PreviewComponent } from './label/preview/preview.component';
import { ConfirmComponent } from './components/confirm/confirm.component';

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		ErrorComponent,
		SearchComponent,
		MoveComponent,
		PlaceComponent,
		RemoveComponent,
		LabelComponent,
		SvgComponent,
		PreviewComponent,
		ConfirmComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		ModalModule.forRoot(),
		BsDropdownModule.forRoot(),
		FormsModule,
		TypeaheadModule.forRoot()
	],
	providers: [CookieService],
	bootstrap: [AppComponent],
	entryComponents: [
		ConfirmComponent
	]
})
export class AppModule { }

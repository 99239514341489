import { Component, OnInit, Input } from '@angular/core';
import { ErrorsService } from 'src/app/services/errors.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  @Input() field: string;

  constructor(
    private Errors:ErrorsService
  ) { }

  ngOnInit() {
  }

  get error(): any {
		return this.Errors.errors[this.field];
	}

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  _title : any;
  constructor() { }

  get title() :any {
    return this._title;
  }

  setTitle(title){
    this._title = title;
  }
}

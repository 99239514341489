import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { Observable, Observer } from 'rxjs';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorsService } from '../services/errors.service';

@Component({
	selector: 'app-label',
	templateUrl: './label.component.html',
	styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {

	searchIn: any = 'projects';
	dataSource: any;
	query: any = '';
	units: any = [];
	containerItems: any = [];
	subQuery: any = '';
	selectedProjectToken : any;

	label: any = {
		bin: {},
		itemCount: 0
	};

	constructor(
		private Data: DataService,
		private route: ActivatedRoute,
		private router: Router,
		private Api: ApiService,
		private Errors : ErrorsService
	) {
		this.Data.setTitle('Create new label');
		this.dataSource = Observable.create((observer: Observer<any[]>) => {
			if(this.query){
				this.Api.get('search/' + this.searchIn + '/' + this.query).subscribe((responseItems: any) => {
					observer.next(responseItems.results || []);
				});
			}else{
				observer.next([]);
			}
		});
	}

	ngOnInit() {
	}

	changeSearchIn(){
		this.query = '';
		this.reset()
	}
	reset(){
		this.selectedProjectToken = undefined;
		this.subQuery = '';
		this.units = [];
		this.containerItems = [];
		this.label = {
			bin: {},
			itemCount: 0
		};
	}

	onSelect(event) {

		this.reset();

		if (this.searchIn === 'projects') {
			this.selectedProjectToken = event.item.token;
			this.Api.get('units/' + event.item.token).subscribe(units => {
				this.units = units;
			});
		} else {
			this.Api.get('containers/' + event.item.token + '/items').subscribe((res:any) => {
				this.containerItems = res.containerItems;
				this.containerItems.forEach(item => {
					item.name = item.project.name + ' - ' + item.unit.unitNumber;
				});
			});
		}

	}

	onUnitSelect(event) {
		this.label.unit = event.item;
	}

	onItemSelect(event) {
		this.selectedProjectToken = event.item.projectToken;
		this.label.containerItemToken = event.item.containerItemToken;
		this.label.unit = event.item.unit;
	}

	onSubBlur(event){
		this.subQuery = '';
	}

	setBoxCount(direction) {
		this.label.itemCount = direction === '+' ? this.label.itemCount + 1 : Math.max(0, this.label.itemCount - 1);
	}

	previewLabel(print?) {

		this.Api.save('pallets', {
			projectToken : this.selectedProjectToken,
			unitToken : this.label.unit ? this.label.unit.unitToken : undefined,
			containerItemToken : this.label.containerItemToken,
			notes : this.label.notes,
			itemCount : this.label.itemCount
		}).subscribe((pallet: any) => {
			if(pallet.error){
				this.Errors.setErrors(pallet.error);
			}else{
				this.router.navigate(['/preview', pallet.palletToken]);
			}
		});

	}

}
